import React from 'react';
import Cctv from './Cctv';
import Panel from './Panel';
import data from '../data/data.json';
import roadways from '../data/roadways.json';
import panels from '../data/panels.json';
import { Grid } from '@material-ui/core';
// redux
import { connect } from 'react-redux';

const Body = (props) => {

    let filteredData = data;
    if (props.roadway.roadway === "norte")
        filteredData = filteredData.filter(cctv => roadways[0].cctvs.indexOf(cctv.image) > -1)
    if (props.roadway.roadway === "sul")
        filteredData = filteredData.filter(cctv => roadways[1].cctvs.indexOf(cctv.image) > -1)
    //console.log("Body, loadstage", props.loadStage, props.roadway,filteredData)


    let items = []
    filteredData.forEach(cctv => {
        // create cctv panel component
        let cctv_panels = panels.filter(panel => panel.cctv === cctv.image)
        let cctv_panels_components = []
        cctv_panels.forEach(cctv_panel => {
            const cctv_panel_data = props.panelsData.filter(paneldata => paneldata.id === cctv_panel.id)[0]
            if (cctv_panel_data)
                cctv_panels_components.push({ id: cctv_panel_data.id, sentido: cctv_panel_data.s || cctv_panel.sentido, comp: <Panel text={cctv_panel_data.text} image={cctv_panel_data.img} sentido={cctv_panel_data.s || cctv_panel.sentido} /> })
        })

        // create cctv component
        const cctvProps = {}
        // if (props.loadStage === "loaded"){
        //cctvProps.uri = `http://www.vialitoral.com/imagens/${cctv.image}/snap_c1.jpg`
        //cctvProps.uri = `http://www.vialitoral.com/cctv.php?image=${cctv.image}`
        cctvProps.uri = `https://images.weserv.nl/?url=http://www.vialitoral.com/imagens/${cctv.image}/snap_c1.jpg`
        cctvProps.component = "img"
        if (props.loadStage === "loaded" && cctv.image === "065") {
            cctvProps.onLoad = props.handleOnLoad
        }

        const cctvC = <Cctv
            name={cctv.name}
            junction={cctv.junction}
            {...cctvProps}
        />

        // push to grid
        cctv_panels_components.forEach(cctv_panel_component => {
            if (cctv_panel_component.sentido === "sul" && props.roadway.roadway !== "norte")
                items.push({ id: `panel_${cctv_panel_component.id}`, component: cctv_panel_component.comp })
        })

        items.push({ id: `${cctv.image}`, component: cctvC })

        cctv_panels_components.forEach(cctv_panel_component => {
            if (cctv_panel_component.sentido === "norte" && props.roadway.roadway !== "sul")
                items.push({ id: `panel_${cctv_panel_component.id}`, component: cctv_panel_component.comp })
        })
    })

    if (props.loadStage === "loading") return null;
    return (
        <Grid container spacing={2} style={{ maxWidth: "600px" }} >
            {items.map(item => {
                return <Grid item xs={12} key={item.id} id={item.id}>
                    {item.component}
                </Grid>
            })}
        </Grid>
    )
};

const mapStateToProps = (state) => ({
    roadway: state.roadway,
    loadStage: state.loadStage,
    panelsData: state.panelsData,
});

const BodyContainer = connect(mapStateToProps)((Body));

export default BodyContainer;
