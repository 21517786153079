import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      position: "fixed",
      bottom: 0,
      left: 0
    },
  },
  fab: {
    background: "#eee",
    color: "#1e77ba"
  },
}));

const DownloadFab = (props) => {
  const classes = useStyles();

  return (
    <div id="install" className={classes.root} style={{ display: "none" }}>
      <Fab className={classes.fab} aria-label="add">
        <InstallMobileIcon />
      </Fab>
    </div>
  );
};



export default DownloadFab;
