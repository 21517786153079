import React, { useEffect } from 'react';
import { useScrollPosition } from './useScrollPosition';

import Slider from '@material-ui/core/Slider';
import data from '../data/data2.json';
import panels from '../data/panels.json';

import './JunctionSlider.css';
// redux
import { connect } from 'react-redux';

function JunctionSlider(props) {
  const [sliderPos, setSliderPos] = React.useState(null);
  let sliderRanges = [0]
  let _marks = []

  data.forEach(cctv => {
    // create cctv panel component
    let cctv_panels = panels.filter(panel => panel.cctv === cctv.image)
    let cctv_panels_components = []
    cctv_panels.forEach(cctv_panel => {
      const cctv_panel_data = props.panelsData.filter(paneldata => paneldata.id === cctv_panel.id)[0]
      if (cctv_panel_data)
        cctv_panels_components.push({ id: cctv_panel_data.id, sentido: cctv_panel_data.s || cctv_panel.sentido, image: cctv_panel_data.img })
    })

    // push to grid
    cctv_panels_components.forEach(cctv_panel_component => {
      if (cctv_panel_component.sentido === "norte" && props.roadway.roadway !== "sul") {
        _marks.push({ id: `panel_${cctv_panel_component.id}`, image: cctv_panel_component.image })
        sliderRanges.push(sliderRanges[sliderRanges.length - 1] + 0.5)
      }
    })

    if (cctv.junction) {
      _marks.push({ id: `${cctv.image}` })
      sliderRanges.push(sliderRanges[sliderRanges.length - 1])
    }

    sliderRanges[sliderRanges.length - 1]++

    cctv_panels_components.forEach(cctv_panel_component => {
      if (cctv_panel_component.sentido === "sul" && props.roadway.roadway !== "norte") {

        _marks.push({ id: `panel_${cctv_panel_component.id}`, image: cctv_panel_component.image })
        sliderRanges.push(sliderRanges[sliderRanges.length - 1] + 0.5)
      }
    })
  })

  sliderRanges.pop()

  const calculateScrollToSliderPos = (scrollPos, sliderRanges) => {
    //console.log("calculateScrollToSliderPos", sliderRanges)
    const nearestIndex = sliderRanges.findIndex(currentNum => scrollPos < currentNum)
    const rangeScrollPos = scrollPos - sliderRanges[nearestIndex - 1]
    const rangeSize = sliderRanges[nearestIndex] - sliderRanges[nearestIndex - 1]
    const rangeProgress = rangeScrollPos / rangeSize
    //console.log("calculateScrollToSliderPos", scrollPos, "nearestIndex", nearestIndex, rangeSize, rangeProgress, nearestIndex - 1 + rangeProgress)
    return nearestIndex - 1 + rangeProgress
  }
  useScrollPosition(({ currPos, ...other }) => {
    //console.log("useScrollPosition", currPos )
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const progress = sliderRanges[sliderRanges.length - 1] - (-currPos.y) / docHeight * sliderRanges[sliderRanges.length - 1]
    //console.log("useScrollPosition y : ", currPos.y, progress, sliderRanges[sliderRanges.length - 1])
    const sliderProgress = calculateScrollToSliderPos(progress, sliderRanges)
    setSliderPos(sliderProgress)
  }, [sliderRanges])

  useEffect(() => {
    _marks.forEach((_marks, index) => {
      document.getElementById("slider").querySelector(`[data-index='${index}']`).classList.remove("red");
      document.getElementById("slider").querySelector(`[data-index='${index}']`).classList.remove("blue");

      if (_marks.image)
        document.getElementById("slider").querySelector(`[data-index='${index}']`).classList.add(imageColor(_marks.image))
    })

  })

  return (
    <Slider
      id="slider"
      orientation="vertical"
      aria-labelledby="discrete-slider"
      valueLabelDisplay="off"
      //valueLabelFormat={index => {/*console.log(index);*/ return Number.isInteger(index) ? cctvs[index].junction.name : ""}}
      // marks={marks}
      marks
      track={false}
      min={0}
      max={_marks.length - 1}
      onChange={(event, index) => props.onChange(event, index, _marks[index].id)}
      value={sliderPos}
    />
  );
}

const mapStateToProps = (state) => ({
  roadway: state.roadway,
  panelsData: state.panelsData,
});

const JunctionSliderContainer = connect(mapStateToProps)(React.memo(JunctionSlider));

export default JunctionSliderContainer;


function imageColor(image) {
  return ((image >= 128 && image <= 170) || (image >= 238 && image <= 252) || image === 209) ? "red" : "blue";
}