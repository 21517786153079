import { combineReducers, createStore } from 'redux';

// actions.js
export const pickRoadway = (roadway) => ({
  type: 'PICK_ROADWAY',
  data: {roadway},
});

export const clearRoadway = () => ({
  type: 'CLEAR_ROADWAY',
});

// reducers.js
export const roadway = (state = {}, action) => {
  switch (action.type) {
    case 'PICK_ROADWAY':
      return action.data;
    case 'CLEAR_ROADWAY':
      return {};
    default:
      return state;
  }
};

// actions.js
export const setLoaded = () => ({
  type: 'SET_LOADED',
  data: 'loaded',
});

export const setRendered = () => ({
  type: 'SET_RENDERED',
  data: 'rendered',
});

// reducers.js
export const loadStage = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LOADED':
    case 'SET_RENDERED':
      return action.data;
    default:
      return state;
  }
};

// actions.js
export const setPanelsData = (data) => ({
  type: 'SET_PANELS_DATA',
  data: data,
});

// reducers.js
export const panelsData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PANELS_DATA':
      return action.data;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  roadway,
  loadStage,
  panelsData
});

// store.js
export function configureStore(initialState = {loadStage: 'loading', panelsData:[]}) {
  const store = createStore(reducers, initialState);
  return store;
}

export const store = configureStore();
