import React, {useEffect} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Body from './components/Body';
import RoadwaysFilter from './components/RoadwaysFilter';
import DownloadFab from './components/DownloadFab';
import JunctionSlider from './components/JunctionSlider';
// import Scripts from './Scripts';
import './App.css';
// redux
import { connect } from 'react-redux';
import { setLoaded, setRendered } from './redux';
import { setPanelsData } from './redux';
//functions
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";

const app = initializeApp({
    projectId: 'viarapida1',
    apiKey: 'AIzaSyA8k-23HIiVVzrXBM812wzPudMkxpcXTCk',
    authDomain: 'viarapida1.firebaseapp.com',
});
const functions = getFunctions(app);
const getpanelinfo = httpsCallable(functions, 'getpanelinfo');


function App(props) {
  //console.log("App", props)

  const handleSliderChange = (event, index, newValue) => {
    // console.log( "handleSliderChange", newValue)
    scrollToMiddle(newValue)
  };


  const scrollToMiddle = id => {
    const { top, height } = document.getElementById(id).getBoundingClientRect();
    window.scrollTo(0, top + window.pageYOffset + (height - window.innerHeight) * 0.5);
  }


  // console.log(props)

  const handleOnLoad = () => {
    // console.log("handleOnLoad")
    props.setRendered()
    scrollToMiddle("065")
  }


  /* Load stuff */
  let loadTimer = null;
  useEffect(() => {
    //console.log("App useEffect")

    getpanelinfo()
    .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // const sanitizedMessage = data.text;
        //console.log(result)

        props.setPanelsData(result.data)
    }).catch((error) => {
        // Getting the Error details.
        // const code = error.code;
        // const message = error.message;
        // const details = error.details;
        console.log("error", error)
    });

    const loadTimer = setTimeout(() => {
      // console.log('1 second passed, no beforeinstallprompt triggered, setting loaded anyway!')
      props.setLoaded()
    }, 250);
    return () => clearTimeout(loadTimer);
  }, []);

  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (e) => {
    //alert("beforeinstallprompt")
    clearTimeout(loadTimer)
    loadTimer = null;

    const addBtn = document.querySelector('#install');
    //addBtn.style.display = 'none';


    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI to notify the user they can add to home screen
    addBtn.style.display = 'block';

    addBtn.addEventListener('click', (e) => {
      // hide our user interface that shows our A2HS button
      //addBtn.style.display = 'none';
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
            addBtn.style.display = 'none';
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
    });

  });

  return (
    <div className={`App ${props.loadStage}`} >
        <Backdrop 
        style={{
          zIndex: 2000000,
          color: '#fff',
        }} 
        open={props.loadStage !== "rendered"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <header className="App-header" >
        <Body handleOnLoad={handleOnLoad}/>
        <DownloadFab />
        <RoadwaysFilter/>
        <JunctionSlider onChange={handleSliderChange}/>
      </header>
      {/* <Scripts/>  */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loadStage: state.loadStage,
});

const mapDispatchToProps = {
  setLoaded,
  setRendered,
  setPanelsData
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(React.memo(App));

export default AppContainer;
