
import React from "react";
import { Card, CardMedia, Box, Paper, Typography } from '@mui/material';

import "./Panel.css";

export default props => {
  // static propTypes = {
  //   id: PropTypes.string,
  //   name: PropTypes.string,
  //   uri: PropTypes.string,
  //   junction: PropTypes.object,
  // };

  const nome_sentido = props.sentido === -1 ? "" : props.sentido
  const desc_sentido = props.sentido === -1 ? "" : props.sentido === "norte" ? "Sentido Machico - Ribeira Brava" : "Sentido Ribeira Brava - Machico"


  return (
    <Card className="component-panel" sx={{ display: 'flex', flexDirection: 'column', backgroundColor: "#000d" }} >
      <span className="roadway">
        {`${nome_sentido.toUpperCase()} — ${desc_sentido}`}
      </span>
      <Paper sx={{ backgroundColor: "black", padding: "2%" }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <CardMedia
            className="image"
            component="img"
            sx={{ width: "20%" }}
            image={`/map/${props.image}.svg`}
            alt=""
          />
          <Typography className="text message" component="div" variant="h5">
            {`${props.text}`}
          </Typography>
        </Box>
      </Paper>
    </Card >
  );
}