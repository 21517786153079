import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';


// const images = [
//   {
//     url: '/static/images/grid-list/breakfast.jpg',
//     title: 'Breakfast',
//     width: '40%',
//   },
//   {
//     url: '/static/images/grid-list/burgers.jpg',
//     title: 'Burgers',
//     width: '30%',
//   },
//   {
//     url: '/static/images/grid-list/camera.jpg',
//     title: 'Camera',
//     width: '30%',
//   },
// ];

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  //     minWidth: 300,
  //     width: '100%',
  //   },
  //   image: {
  //     position: 'relative',
  //     height: 200,
  //     [theme.breakpoints.down('xs')]: {
  //       width: '100% !important', // Overrides inline-style
  //       height: 100,
  //     },
  //     '&:hover, &$focusVisible': {
  //       zIndex: 1,
  //       '& $imageBackdrop': {
  //         opacity: 0.15,
  //       },
  //       '& $imageMarked': {
  //         opacity: 0,
  //       },
  //       '& $imageTitle': {
  //         border: '4px solid currentColor',
  //       },
  //     },
  //   },
  //   focusVisible: {},
  imageButton: {
    // position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    padding: '6px',
    margin: '6px',
    background: '#eee',
    borderRadius: '3px',
    /* justify-content: center;*/
    // color: theme.palette.common.white,
  },
  titleIcon: {
    // position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between'
    // flexFlow: 'column',
    // alignItems: 'flex-start',
    // padding: '6px',
    // margin: '6px',
    // background: '#eee',
    // borderRadius: '3px',
    /* justify-content: center;*/
    // color: theme.palette.common.white,
  },
  selectedNorte: {
    background: "#1e77ba",
    color: "white"
  },
  selectedSul: {
    background: "#1e77ba",
    color: "white"
  }
  //   imageSrc: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center 40%',
  //   },
  //   imageBackdrop: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //     backgroundColor: theme.palette.common.black,
  //     opacity: 0.4,
  //     transition: theme.transitions.create('opacity'),
  //   },
  //   imageTitle: {
  //     position: 'relative',
  //     padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  //   },
  //   imageMarked: {
  //     height: 3,
  //     width: 18,
  //     backgroundColor: theme.palette.common.white,
  //     position: 'absolute',
  //     bottom: -2,
  //     left: 'calc(50% - 9px)',
  //     transition: theme.transitions.create('opacity'),
  //   },
}));

export default function RoadwaysFilterButton(image) {
  const classes = useStyles();
  const buttonClasses = [classes.imageButton]
  if (image.selected) {
    buttonClasses.push(classes[`selected${image.name}`])
  }
  //console.log(image.selected, buttonClasses, `selected${image.name}`)
  return (
    <div className={classes.root}>
      {/* {images.map((image) => ( */}
      <ButtonBase
        focusRipple
        key={image.name}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{
          width: image.width,
        }}
        onClick={image.onClick}
      >
        {/* <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          /> */}
        {/* <span className={classes.imageBackdrop} /> */}
        <span className={buttonClasses.join(" ")}>
          <span className={classes.titleIcon}>

            <Typography
              component="span"
              variant="button"
              color="inherit"
              className={classes.imageTitle}
            >
              {image.name}
              {/* <span className={classes.imageMarked} /> */}
            </Typography>
            <ArrowRightAlt
              className={classes.icon}
              style={image.name === "Norte" ? { transform: "rotate(180deg)" } : {}}
            />
          </span>
          <Typography
            component="span"
            variant="caption"
            color="inherit"
            className={classes.imageTitle}
          >
            {image.subtitle}
            {/* <span className={classes.imageMarked} /> */}
          </Typography>
        </span>
      </ButtonBase>
      {/* ))} */}
    </div>
  );
}