import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { SyncAlt, ArrowRightAlt } from '@mui/icons-material';
import Popover from '@material-ui/core/Popover';
import RoadwaysFilterButton from './RoadwaysFilterButton';
// redux
import { connect } from 'react-redux';
import { pickRoadway, clearRoadway } from '../redux';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      position: "fixed",
      bottom: 0,
      right: 0
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    background: "#eee",
    color:"#1e77ba"
  },
  fabNorte: {
    background: "#1e77ba",
    color: "white"
  },
  fabSul: {
    background: "#1e77ba",
    color: "white"
  }
}));

const RoadwaysFilter = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleFabClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (value) => {
    //console.log("cl")
    if (value !== props.roadway.roadway)
      props.pickRoadway(value)
    else
      props.clearRoadway()

    handleClose()
  };
  // console.log(props)

  // React.useEffect( (prevProps, prevState, snapshot) => {
  //   console.log("RF",prevProps, prevState, snapshot);

  // }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const icon = props.roadway.roadway
    ? <ArrowRightAlt style={props.roadway.roadway === "norte" ? { transform: "rotate(180deg)" } : {}} />
    : <SyncAlt style={{ transform: "rotateX(180deg)" }} />

  const fabClass = props.roadway.roadway
    ? (props.roadway.roadway === "norte" ? classes.fabNorte : classes.fabSul)
    : classes.fab;

  //console.log(props, fabClass)

  return (
    <div className={classes.root}>
      <Fab aria-describedby={id} className={fabClass} aria-label="add" onClick={handleFabClick}>
        {icon}
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <RoadwaysFilterButton
          name="Norte"
          subtitle="Sentido Machico - Ribeira Brava"
          onClick={() => handleButtonClick("norte")}
          selected={props.roadway.roadway === "norte"}
        />
        <RoadwaysFilterButton
          name="Sul"
          subtitle="Sentido Ribeira Brava - Machico"
          onClick={() => handleButtonClick("sul")}
          selected={props.roadway.roadway === "sul"}
        />
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  roadway: state.roadway,
});

const mapDispatchToProps = {
  pickRoadway,
  clearRoadway,
};

const RoadwaysFilterContainer = connect(mapStateToProps, mapDispatchToProps)(React.memo(RoadwaysFilter));

export default RoadwaysFilterContainer;
