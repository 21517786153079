  
import React from "react";
import PropTypes from "prop-types";
import "./JunctionIcon.css";

export default class JunctionIcon extends React.Component {
  static propTypes = {
    text: PropTypes.number,
  };


  render() {

    return (
      <div className="junctionIcon">
        <span>
          {this.props.text}
        </span>
      </div>
    );
  }
}