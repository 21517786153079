import React, { useState } from "react";
import VizSensor from 'react-visibility-sensor';
import { Card, CardMedia, Paper } from '@material-ui/core'; //TODO substituir por @mui/material
import LinearProgress from '@mui/material/LinearProgress';

import JunctionIcon from './JunctionIcon';
import "./Cctv.css";

export default props => {
  // static propTypes = {
  //   id: PropTypes.string,
  //   name: PropTypes.string,
  //   uri: PropTypes.string,
  //   junction: PropTypes.object,
  // };

  // state = {
  //   imageUrl : ""
  // }
  //console.log("CCtv")
  const [imageUrl, setImageUrl] = useState('')
  const [nextImageUrl, setNextImageUrl] = useState('')
  const [timerID, setTimerID] = useState('')
  const [loadState, setLoadState] = useState('')

  // render() {
  // useEffect(() => {
  //   console.log(props)
  //   // interval = setInterval(() => setNextImageUrl(`${props.uri}?t=${Date.now()}`), 10000);
  //   // interval = setInterval(() => setNextImageUrl(`${props.uri}`), 10000);
  //   interval = setInterval(() => setImageUrl(`${props.uri}?t=${Date.now()}`), 10000);
  //   // interval = setInterval(() => setImageUrl(`${props.uri}`), 10000);
  // }, []);

  const setAutoloadTimer = () => {
    // console.log(props)
    setTimerID(setInterval(() => {
      setLoadState("fetching")
      setNextImageUrl(`${props.uri}?t=${Date.now()}`)
    }, 10000));
    //console.log("setAutoloadTimer",timerID);
    // interval = setInterval(() => setNextImageUrl(`${props.uri}`), 10000);
    // interval = setInterval(() => setImageUrl(`${props.uri}?t=${Date.now()}`), 10000);
    // interval = setInterval(() => setImageUrl(`${props.uri}`), 10000);
  };

  const unsetAutoloadTimer = () => { /*console.log("unsetAutoloadTimer",timerID);*/  clearInterval(timerID); };


  const onNextLoaded = () => {
    setImageUrl(nextImageUrl)
    setNextImageUrl("")
    setLoadState("")
  }

  const className = [
    "component-cctv",
    loadState
  ];

  return (
    <VizSensor
      partialVisibility
      onChange={(isVisible) => {
        //console.log("CCTV", props.id," is visible ? ", isVisible)
        if (isVisible) setAutoloadTimer()
        else unsetAutoloadTimer()
        // this.setState({imgViz: isVisible})
      }}
    >
      <Card id={props.id} className={className.join(" ").trim()} elevation={4}>
        <Paper className="header">
          {props.junction ? <JunctionIcon text={props.junction.number} /> : null}
          {props.junction ? props.junction.name : props.name}
        </Paper>
        <CardMedia className="image" component={props.component} image={imageUrl || `${props.uri}?t=${Date.now()}`} onLoad={props.onLoad} />
        <CardMedia className="holder" component={props.component} image={nextImageUrl} onLoad={onNextLoaded} />
        <LinearProgress className="linearProgress" />
      </Card>
    </VizSensor>
  );
}
